import {CompanyId, Pathology, generatePath} from '@legit.health/ui';
import {ONE_HOUR_IN_MS} from '@legit.health/ui';
import {useCompany} from '@/shared/contexts/UserContext/useUserContext';
import useSuspenseApiQuery from '@/shared/hooks/useSuspenseApiQuery';
import cachedDataState from './utils/cachedDataState';

export const PATHOLOGIES = '/companies/:companyId/pathologies';

export function getQueryKey(companyId: CompanyId) {
  return [PATHOLOGIES, companyId];
}

export default function usePathologies({isCacheFresh}: {isCacheFresh: boolean}) {
  const company = useCompany();
  const path = generatePath(PATHOLOGIES, {companyId: company.id}, null);
  return useSuspenseApiQuery<Pathology[]>({
    queryOptions: {
      queryKey: getQueryKey(company.id),
      staleTime: ONE_HOUR_IN_MS,
      initialData: function () {
        const {pathologies} = cachedDataState.get();
        return isCacheFresh && pathologies
          ? {
              isSuccessfull: true,
              status: 200,
              data: pathologies
            }
          : undefined;
      }
    },
    requestArguments: {
      method: 'GET',
      path
    }
  });
}
