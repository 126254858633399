import {createContext, ReactNode, useCallback, useContext, useEffect, useMemo} from 'react';
import {
  BodySite,
  BodySiteCode,
  BodySiteId,
  useBodySites,
  indexArrayBy,
  useScoringSystems,
  ScoringSystem,
  ScoringSystemId,
  Pathology,
  PathologyId,
  CompanyId,
  ApiResponse
} from '@legit.health/ui';
import {useQueryClient} from '@tanstack/react-query';
import useLoggedUserContext, {useCompany} from '@/shared/contexts/UserContext/useUserContext';
import useAppInfo from './useAppInfo';
import usePathologies, {getQueryKey as getPathologiesQueryKey} from './usePathologies';
import cachedDataState from './utils/cachedDataState';

export type SharedDataContextValue = {
  customPathologies: Pathology[];
  pathologies: Pathology[];
  pathologiesById: Record<PathologyId, Pathology>;
  bodySites: BodySite[];
  bodySitesById: Record<BodySiteId, BodySite>;
  bodySitesByCode: Record<BodySiteCode, BodySite>;
  scoringSystems: ScoringSystem[];
  scoringSystemsById: Record<ScoringSystemId, ScoringSystem>;
  clear: () => void;
  addPathology: (pathology: Pathology, companyId: CompanyId) => void;
  invalidatePathologies: () => void;
};

const SharedDataContext = createContext<SharedDataContextValue>({
  customPathologies: [],
  pathologies: [],
  pathologiesById: {},
  bodySites: [],
  bodySitesById: {},
  bodySitesByCode: {},
  scoringSystems: [],
  scoringSystemsById: {},
  clear: () => {},
  addPathology: () => {},
  invalidatePathologies: () => {}
});

export function useSharedDataContext() {
  return useContext(SharedDataContext);
}

export function SharedDataContextProvider({children}: {children: ReactNode}) {
  const {user} = useLoggedUserContext();
  const {
    dataImportedAt: cachedDataImportedAt,
    locale: cachedLocale,
    bodySites: cachedBodySites,
    scoringSystems: cachedScoringSystems
  } = cachedDataState.get();
  const {
    apiResponse: {
      data: {dataImportedAt}
    }
  } = useAppInfo();
  const isCacheFresh = cachedDataImportedAt === dataImportedAt && user.locale === cachedLocale;
  const queryClient = useQueryClient();
  const company = useCompany();
  const {
    apiResponse: {data: bodySites}
  } = useBodySites({initialData: isCacheFresh && cachedBodySites ? cachedBodySites : undefined});
  const {
    apiResponse: {data: pathologies}
  } = usePathologies({isCacheFresh});
  const {
    apiResponse: {data: scoringSystems}
  } = useScoringSystems({
    initialData: isCacheFresh && cachedScoringSystems ? cachedScoringSystems : undefined
  });

  useEffect(
    function () {
      cachedDataState.set((c) => ({
        ...c,
        scoringSystems
      }));
    },
    [scoringSystems]
  );

  useEffect(
    function () {
      cachedDataState.set((c) => ({
        ...c,
        bodySites
      }));
    },
    [bodySites]
  );

  useEffect(
    function () {
      cachedDataState.set((c) => ({
        ...c,
        pathologies
      }));
    },
    [pathologies]
  );
  const customPathologies = pathologies.filter((p) => p.isCustom);

  const addPathology = useCallback(
    (pathology: Pathology, companyId: CompanyId) =>
      queryClient.setQueryData<ApiResponse<Pathology[]> | null>(
        getPathologiesQueryKey(companyId),
        (value) => ({
          isSuccessfull: true,
          status: 200,
          data: value?.data ? [...value.data, pathology] : [pathology]
        })
      ),
    [queryClient]
  );

  const invalidatePathologies = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: getPathologiesQueryKey(company.id)
      }),
    [company.id, queryClient]
  );

  const value = useMemo(
    () => ({
      bodySites,
      bodySitesById: indexArrayBy(bodySites, 'id'),
      bodySitesByCode: indexArrayBy(bodySites, 'code'),
      customPathologies,
      pathologies,
      pathologiesById: indexArrayBy(pathologies, 'id'),
      scoringSystems,
      scoringSystemsById: indexArrayBy(scoringSystems, 'id'),
      clear: () => cachedDataState.delete(),
      addPathology,
      invalidatePathologies
    }),
    [bodySites, pathologies, scoringSystems, customPathologies, addPathology, invalidatePathologies]
  );

  return <SharedDataContext.Provider value={value}>{children}</SharedDataContext.Provider>;
}
